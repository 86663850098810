import * as React from 'react'
export default function PersonSvg() {
	return (
		<svg viewBox="0 0 20 39" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.49634 14.8014C2.25571 13.4042 1 10.9114 1 8C1 3.10273 4 0 9 0C14 0 17 3.10273 17 8C17 10.9114 15.7443 13.4042 13.5037 14.8014M4.49634 14.8014C5.71897 15.5638 7.23485 16 9 16C10.7651 16 12.281 15.5638 13.5037 14.8014M4.49634 14.8014C2.9509 15.406 1.62836 16.4049 0 18V37.5H9H18V18C16.3716 16.4049 15.0491 15.406 13.5037 14.8014"
				transform="translate(1 1)"
			/>
		</svg>
	)
}
